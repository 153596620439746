<template>
  <div>
    <b-row>
      <!-- Search -->
      <b-col
        cols="12"
        class="mt-2 mb-4"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-form-input
            v-model="searchValue"
            class="d-inline-block mr-1"
            placeholder="Filtrar..."
            @keyup="filtro($event, 'title', filterGlobal)"
          />
        </div>
      </b-col>
    </b-row>

    <b-row>
      <div
        v-for="(item, index) in lives_filtred"
        :key="item.id"
        style="margin: 5px;"
      >
        <a
          v-if="!disabled[index]"
          :style="item.ready === 0 ? `cursor: not-allowed;opacity:1;` : ''"
          @click="goTo(item.slug, item.ready)"
        >
          <b-card
            :title="item.title"
            img-alt="Image"
            :img-src="`${path}/storage/live/image/${item.img}`"
            img-top
            tag="article"
            md="4"
            style="max-width: 20rem;display:block;"
          />
        </a>
        <a v-else>
          <b-card
            :title="item.title"
            img-alt="Image"
            :img-src="`${path}/storage/live/image/${item.img}`"
            img-top
            tag="article"
            md="4"
            style="max-width: 20rem;display:block;"
          >
            <span v-if="disabled[index]">
              {{ item.text_before_ready }}
            </span>
          </b-card>
        </a>
      </div>
      <b-modal
        id="evt-modal-notification"
        size="lg"
        title="Eventos para hoje"
        ok-only
        no-stacking
      >
        <div>
          Você pode ter eventos para hoje:
          <ul
            id="notification"
            style="overflow-y: scroll;height:220px;"
          >
            <li
              v-for="l in liveList"
              :key="l.id"
            >
              {{ l.live.title }}

              <b-button
                variant="info"
                :href="`/lives/${l.live.link}/${l.live.frame.id}`"
              >
                Entrar ao vivo
              </b-button>
            </li>
          </ul>
        </div>
      </b-modal>
    </b-row>
  </div>
</template>

<script>
import LivesServices from './LivesServices'

export default {
  data() {
    return {
      lives: [],
      lives_filtred: [],
      path: '',
      disabled: [],
      liveList: [],
      searchValue: '',
      ready: false,
    }
  },
  mounted() {
    this.path = process.env.VUE_APP_BASE_PUBLIC
    LivesServices().getLives().then(api => {
      this.lives = api.data.response.lives
      this.lives_filtred = this.orderLiveByDate(this.lives)
    })
    setInterval(() => {
      this.isReady()
    }, 10000)

    // get Lives for users
    LivesServices().getLiveSpeakerBySpeaker().then(api => {
      this.liveList = api.data.response.liveSpeaker
      if (this.liveList.length > 0) { this.$bvModal.show('evt-modal-notification') }
    })
  },
  methods: {
    filtro(e, propriedadePesquisa, filterGlobal) {
      if (e.target.value.trim() !== '') {
        const lista = this.lives
        const result = filterGlobal(e, lista, propriedadePesquisa)
        this.lives_filtred = result
      } else {
        this.lives_filtred = this.orderLiveByDate(this.lives)
      }
    },
    isReady() {
      LivesServices().getLives().then(api => {
        this.lives = api.data.response.lives
        this.lives_filtred = this.orderLiveByDate(this.lives)
      })
    },
    goTo(slug, ready) {
      this.ready = ready
      if (this.ready) { window.location.href = `/live/${slug}` }
    },
    orderLiveByDate(arr) {
      return arr.sort((a, b) =>
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        new Date(`${b.date} ${b.time}`) - new Date(`${a.date} ${a.time}`)
      )
    }
  }
}
</script>

<style>

</style>
