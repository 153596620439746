import axios from '@axios'

export default function LivesServices() {
  const getLives = async (queryParams) => {
    const api = await axios.get('/lives', queryParams)

    return api
  }

  const getLive = async (queryParams) => {
    const api = await axios.get('/live', {
      params: queryParams,
    })
    return api
  }

  const getLiveSpeakerBySpeaker = async (queryParams) => {
    const api = await axios.get('/live/speaker/byspeaker', {
      params: queryParams,
    })
    return api
  }

  const getLiveSpeakerByLive = async (queryParams) => {
    const api = await axios.post('/live/speaker/bylive', queryParams)

    return api
  }

  const liveMonitoring = async (queryParams) => {
    const api = await axios.post('/live/monitoring', queryParams)
    return api
  }

  const liveMonitoringInfo = async (queryParams) => {
    const api = await axios.post('/live/monitoring/info', queryParams)
    return api
  }

  return {
    getLives,
    getLive,
    getLiveSpeakerBySpeaker,
    getLiveSpeakerByLive,
    liveMonitoring,
    liveMonitoringInfo,
  }
}
